import { BrowserRouter,Routes,Route} from 'react-router-dom';

import './App.css';

import Inicio from './components/Inicio';
import Provincia from './components/Provincia';
import JuanaManso from './components/JuanaManso';
import AprenderConectado from './components/AprenderConectado';
import Nacion from './components/Nacion';
import Descargas from './components/Descargas';


function App() {


  return (
    <div>
       <div className="App">
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<Inicio />}/>
              <Route path='/inicio' element={<Inicio />}/>
              <Route path='/provincia' element={<Provincia/>} /> 
              <Route path='/JuanaManso' element={<JuanaManso/>} />
              <Route path='/AprenderConectados' element={<AprenderConectado/>} />
              <Route path='nacion' element={<Nacion/>} />
              <Route path='Descargas' element={<Descargas/>} />
              <Route path='*' element={<h1>No existe la página</h1>} /> 
            </Routes>
          </BrowserRouter>
        </div>
  </div>

  );
}

export default App;