
function Mensaje(){

    return(
        <div className='card contOption mt-5'>
        <div className="card-body">
              <div className='col-1 '>
                  <span className="material-symbols-outlined">
                    warning
                  </span>
              </div>
              <div className='col-11'>
                 <p className='text-danger'> IMPORTANTE! Para continuar, debe seleccionar en el menú que tipo de computadora desea desbloquear.</p>
             </div>

             </div>

        </div>
    )
}

export default Mensaje;