
import { useState, useEffect} from 'react';
import Autosuggest from 'react-autosuggest';


function Autocomplete() {

 const [campoOptions, setCampoOptions] = useState([]);

//F6C5E7BFF4528C5ADC60

  useEffect(() => {
    async function loadCampos() {
      try {

        const response = await fetch('https://script.google.com/macros/s/AKfycbylwmhTsVvCV4aN1CC-HW7Ms9VAfbHQB-pMxSMp08vp-aPwDuCSFKh09tvkjGwoS3Uy/exec'
        );
        if (!response.ok) {
          throw new Error('Error al obtener los campos');
        }
        const camposData = await response.json();

        const optionsData = Object.keys(camposData)
        setCampoOptions(optionsData);      

      } catch (error) {
        console.error('Error:', error);
      } finally {
 
      }
    }
    loadCampos();
  }, []);


const[SN, setSN]= useState(campoOptions);
const[value, setValue]= useState("");
const[SNeleccionado, setSNeleccionado]= useState({});

const onSuggestionsFetchRequested=({value})=>{
  setSN(filtrarSN(value));
}

const filtrarSN=(value)=>{

  const inputValue=value.trim().toLowerCase();
  const inputLength=inputValue.length;

  var filtrado= campoOptions.filter((SN)=>{
   
    if(SN.toLowerCase().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(inputValue)){
      if(inputLength >= 5){
        return  SN;
      }
      
    }
  });


    return  filtrado;
  
}

const onSuggestionsClearRequested = () =>{
  setSN([]);
}

const getSuggestionValue=(suggestion)=>{
  return `${suggestion}`;
}

const renderSuggestion=(suggestion)=>(
  <div className='sugerencia' onClick={()=>seleccionarSN(suggestion)}>
    {`${suggestion}`}
  </div>
);

const seleccionarSN=(SN)=>{
  setSNeleccionado(SN);
}

const onChange=(e, {newValue})=>{
  setValue(newValue);
}

const inputProps={
    placeholder:"Ingrese manualmente el nro. S/N",
    id:'SNGenerico',
    className: 'form-control',
    value,
    onChange
};



  return (
    <div >

<div className="input-group mb-3">
  <span className="input-group-text" id="basic-addon1">S/N</span>

     <Autosuggest 
      suggestions={SN}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
     />
 </div>

    </div>
  );
}

export default Autocomplete;