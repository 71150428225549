import React, { useState, useEffect } from 'react';

import imagenJuanaManso from '../assets/img/juana2.jpg';



import imgVerAgente from '../assets/img/veragente.png';
import imgServidor from '../assets/img/cambiardirservidor.jpg';

import Agente from '../assets/software/ThefClien4.8.exe';

const urlDescargaAgente = "https://www.youtube.com/embed/LQtBOiJ50qU?si=oGMUkmZ-6B8DiMrO" 
const urlAplicarPDP = "https://www.youtube.com/embed/ypw3f4RbtEA?si=zW-NyjywGLZgIxDT"



const JuanaManso = () => {

  const respuestaInicial = {
    detalle: '',
    id: '',
    request: '',
    respuesta: '',
    solucion: ''
  }

  const [hwid, setHwid] = useState('');
  const [bt, setBt] = useState('');
  
  const [data, setData] = useState(respuestaInicial);
 
  let contPDP = document.querySelector('#contPDP')
  let contCODIGO = document.getElementById('contCODIGO')
  let contAgente = document.getElementById('contAgente')

  let inputSN = document.querySelector('#inputSN')
  let contHWID =  document.querySelector('#contHWID');
 
  let contSolucion = document.querySelector('#contSolucion')



  const cambioHWID = (e) =>{
    setHwid(e.target.value);
  }

  const cambioBT = (e) =>{
    setBt(e.target.value);
  }

 
  const obtenerSolucion = (e) => {

    e.preventDefault();



    console.log('HWID:', hwid);
    console.log('BT:', bt);

    let snSponsor = 'FE065AB52323227ADEB8'

    let PARAMETROS = '?SN='+snSponsor+'&'+'HWID='+hwid+'&'+'BT='+bt

    if (!hwid || !bt) {
      alert('Por favor, complete todos los campos.');
      return;
    }else{

      setData(respuestaInicial) 
      buscarSolucion(PARAMETROS);
    }

  };

  const buscarSolucion = async (PARAMETROS) => {

    console.log(PARAMETROS) 


    let contNull = document.getElementById('contNull')
    contNull.classList.add('d-none')

    contCODIGO.classList.add('d-none')
    contAgente.classList.add('d-none')
    contPDP.classList.add('d-none')
  
    contSolucion.classList.remove('d-none')
    contSolucion.scrollIntoView({ behavior: 'smooth' });


     await fetch('https://script.google.com/macros/s/AKfycbxBbYHBuHjqMPhGvwpY-RiqU3pTXOwbl-ibg6Lp3sRHiSJGS0uShno4vyF8iAkvy_nMAw/exec'+PARAMETROS)
     .then(response => response.json()) 
     .then(data => { 

         console.log(data)
         setData(data) 


         if(data.request === false){

          console.log("No encontramos solución pa")
          contNull.classList.remove('d-none')

          
       }else{              
          controlSolucion(data)
       }

      })
     .catch(error => console.error('Error fetching data for Option 1:', error));  
     
     
};

const controlSolucion = (data) =>{

  let contPaquete = document.getElementById('contPaquete')
  let pdpSolucion = document.getElementById('pdpSolucion')


  contCODIGO.classList.add('d-none')
  contAgente.classList.add('d-none')
  contPDP.classList.add('d-none')


  if(data.solucion === 'PDP' || data.solucion === 'MIGRACION' ){

    contPDP.classList.remove('d-none')
    contAgente.classList.remove('d-none')

    const btn_desbloqueo = downloadBase64File(data.archivo, data.nombre);
    console.log(btn_desbloqueo)

    contPaquete.innerHTML = ''
    contPaquete.appendChild(btn_desbloqueo)

  }else if(data.solucion === 'CODIGO'){

  
    contCODIGO.classList.remove('d-none')
    contAgente.classList.remove('d-none')
    console.log(data.respuesta)
   
  }



 }

 const [isOpen, setIsOpen] = useState(false);

 const toggleCollapse = () => {
   setIsOpen(!isOpen);
 };

 function downloadBase64File(contentBase64, fileName) {
   
  const linkSource = `data:application/zip;base64 ,${contentBase64}`;
  const downloadLink = document.createElement('a');

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.id = "a_tcopp"
  downloadLink.setAttribute("class", "btn btn-outline-success");
  downloadLink.setAttribute("type", "button")
  downloadLink.innerText = "Descargar"

  return downloadLink; 
};


  return (
    <div className="container contOption">

    <div className="card mb-4">
    <div className="card-body">
      <h2 className="card-title">Equipos de Nación</h2>
      <div className="row">
          <div className="col-12 mt-3">

          <div className='row'>
                <div className='col-1 mt-2 d-flex justify-content-end'>
                    <span className="material-symbols-outlined">
                      error
                    </span>
                </div>
                <div className='col-10'>
                   <p className='text-warning'> IMPORTANTE! Para realizar el desbloqueo debés prender el equipo como se muestra en el video, 
                      ya que utilizaremos los numeros que se observan en la pantalla</p>
               </div>

          </div>

          </div>
          <div className="col-12 d-flex justify-content-center contVideo">
                <iframe 
                    width="520" 
                    height="315" 
                    src="https://www.youtube.com/embed/_EcnNhO946M?si=g_wRJua01gnclvDU" 
                    title="YouTube video player" 
                    className='video'
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen>

                </iframe>
          </div>

      </div>
    </div>
    </div>

    <div id="contHWID" className=''>
          <div className="col-12 mt-4 ">
              <div className="card">
                <div className="card-body">
                    <div className='row mt-2 mb-2'>
                          <div className='col-1 mt-2 d-flex justify-content-end'>
                              <span className="material-symbols-outlined">
                                error
                              </span>
                          </div>
                          <div className='col-8'>
                            <p className='text-dark'>
                                Debe ingresar HWID Y BT que se observan en la pantalla de su netbook prendida, sin guiones 
                            </p>
                          </div>
                          <div className='col-12'>
                              <div className='row'>
                              <form className="row g-3 needs-validation">
                                      <div className='col-12 col-md-10 d-none'>
                                          <div className="input-group mb-3">
                                            <span className="input-group-text" id="inputGroup-sizing-default">Serial Number</span>
                                            <input type="text" className="form-control" disabled id="inputSN" value="FE065AB52323227ADEB8"/>
                                          </div>

                                      </div>
                                      <div className="col-10">
                                          <div className="input-group has-validation">
                                          <span className="input-group-text" id="inputGroupPrepend">Hardware ID</span>
                                          <input 
                                              type="text" 
                                              value={hwid} 
                                              onChange={cambioHWID}
                                              className="form-control" 
                                              id="validationCustomUsername" 
                                              aria-describedby="inputGroupPrepend" 
                                              required
                                          />
                                          <div className="invalid-feedback">
                                            Please choose a username.
                                          </div>
                                        </div>
                                      </div>
                                      <div className='col-10'>
                                          <div className="input-group mb-3">
                                                <span className="input-group-text" id="inputGroup-sizing-default">Boot Tick</span>
                                                <input 
                                                    type="text" 
                                                    value={bt} 
                                                    onChange={cambioBT}
                                                   
                                                    className="form-control"  
                                                    id="inputBT" 
                                                    required
                                                />
                                          </div>         
                                      </div>

                                      <div className='col-12 mt-2 d-flex justify-content-end'>
                                          <div className='row'>
                                              <div className='col-10'>
                                                <button 
                                                    type="submit" 
                                                    className="btn btn-outline-secondary mt-3 mx-2" 
                                                    onClick={obtenerSolucion}>
                                                    Continuar
                                                </button>
                                              </div>
                                          </div>
                                      </div>
                                  </form>
                              </div>
                          </div>
                    </div>
                </div>
              </div>
          </div>  
      </div>


      <div className="card mb-4 mt-4 d-none" id="contSolucion">

          <div className="card-body">

          <div className='row mt-2 mb-2'>
                    <div className='col-8'>
                      <h3 className='text-dark'>
                          Solución
                      </h3>
                    </div>
                    
                          {data.detalle != ''? (
                     
                              <div className='col-12 mt-2 mb-3 mx-2'>
                                <figcaption className="blockquote-footer">
                                    <cite title="Source Title">{data.solucion} (ID{data.id})</cite>
                                  </figcaption>
                              </div>   

                          ):(
                            <div className='col-12 text-center'>

                              <div className="spinner-border" role="status">
                                  <span className="visually-hidden">Loading...</span>
                              </div>
                              <br/>
                              <p>Buscando</p>
                            </div>  
                          )
                          
                          }
                          <div className='col-12 d-none' id="contPDP">  
                          <div className='row'>
                             <div className='col-12'>
                             <div className='row'>
                                <div className='col-1'>
                                  <span className="material-symbols-outlined">
                                    counter_1
                                  </span>
                                </div>
                                <div className='col-10'>
                                    <p className="card-text">Descargue el archivo y aplique segun intrucciones</p>
                                    <p className='text-danger'> ATENCION: El archivo puede venir comprimido, debe descomprimir y obtener el archivo de nombre: tcopp</p>

                                    <h4 className="card-title text-success text-center"> {data.respuesta} </h4>
                                </div>
                          </div>
                             </div>
                             <div className='col-10 d-flex justify-content-center ' id='contPaquete'>

                             </div>

                             <div className='col-10 mt-2 mx-4 mb-4'>
                             <div className="card-body">
                                        <div className='video-responsive'>
                                          <iframe 
                             
                                             src={urlAplicarPDP}
                                             title="Video explicativo de como descargar e instalar el agente" 
                                                                           
                                             >
                                          </iframe>
                                      </div> 
                                 </div>


                                </div>

                          </div>
                         

                          </div> 

                          <div className='col-12 d-none' id='contCODIGO'>  

                            <div className='row'>
                                <div className='col-1 mt-2 '>
                                  <span className="material-symbols-outlined">
                                    counter_1
                                  </span>
                                </div>
                                <div className='col-10'>
                                    <p className="card-text">En la pantalla de tu computadora bloqueada, presiona la letra 'y' e inserta el siguiente codigo, luego ENTER</p>
                                    <h4 className="card-title text-success text-center"> {data.respuesta} </h4>
                                </div>

                          </div>
                          </div>

                          <div className='col-12' id='contAgente'>  
                          < div className='row mt-4'>
                                <div className='col-1 mt-2 '>
                                  <span className="material-symbols-outlined">
                                    counter_2
                                  </span>
                                </div>
                                <div className='col-8'>
                                    <p className="card-text mt-2">Conectate a internet y abrí el AGENTE como a continuación</p>
                                    <img src={imgVerAgente} className="d-block w-100" alt="..."/>
                                </div>
                          </div>

                          <div className='row mt-4'>
                                <div className='col-1 mt-2 '>
                                  <span className="material-symbols-outlined">
                                    counter_3
                                  </span>
                                </div>
                                <div className='col-8'>
                                    <p className="card-text mt-2">En la pestaña configuración verifique que el servidor apunte a la URL</p>
                                    <img src={imgServidor} className="d-block w-100" alt="..."/>
                                    <p className='text-dark mt-2'>URL/Direccion del servidor: <mark>citd.dgp.educ.ar</mark></p>
                                </div>
                                <div className='col-12 mx-3 mt-4'>
                                <p className="lead">
                                   NOTA: Si no tenes el programa para cargar los arranques instalado, mirá el siguiente video:                               
                                </p>
                                </div>
                                <div className='col-12 d-flex justify-content-center'>
                                      <button
                                      className="btn btn-outline-primary mb-2"
                                      type="button"
                                      onClick={toggleCollapse}
                                      >
                                      Ver
                                      </button>
                                  </div>

                                <div className=' col-10 mt-2 mx-4 mb-4 '>
                                    <div className={`collapse${isOpen ? ' show' : ''}`}>
                                      <div className="card-body">
                                        <div className='video-responsive'>
                                          <iframe 
                                             width="560"
                                             height="315" 
                                             src={urlDescargaAgente}
                                             title="Video explicativo de como descargar e instalar el agente" 
                                                                              
                                             >
                                             </iframe>
                                          </div>   
                                          <a 
                                            className="btn btn-outline-success mt-3 mb-3" 
                                            href={Agente}
                                            download="ThefClient4.8.exe">
                                              Descargar
                                          </a>
                                          
                                       </div>
                                    </div>
                                </div>
                          </div>                   


                         
                            
                            </div>
                          </div>  


                          </div>  
                          <div className="card-body d-none " id="contNull">
   
                          <div className='row mb-3' >
                                <div className='col-1 text-center'>
                         
                                  <span className="material-symbols-outlined">
                                    warning
                                  </span>
                                 
                                </div>
                                <div className='col-9'>

                                    <p className="card-text">No obtuvimos respuesta del servidor</p>
                                    <p className='text-danger'> ATENCION: Verifique los campos ID de Harware y/o Boot Tick y vuelva intentar</p>

                                    <h4 className="card-title text-success text-center"> {data.respuesta} </h4>
                                </div>
                          </div>            
                      </div>
          </div>


    
    </div>
  );
};

export default JuanaManso;