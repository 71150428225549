
import React, { useState, useEffect} from 'react';


import IRM_2 from '../assets/software/IRM_2.6.bat';
import imgVerAgente from '../assets/img/veragente.png';
import imgServidor from '../assets/img/cambiardirservidor.jpg';
import Agente from '../assets/software/ThefClien4.8.exe';

const urlDescargaAgente = "https://www.youtube.com/embed/LQtBOiJ50qU?si=oGMUkmZ-6B8DiMrO" 
const urlAplicarPDP = "https://www.youtube.com/embed/ypw3f4RbtEA?si=zW-NyjywGLZgIxDT"
const videoArrProvincia = "https://www.youtube.com/embed/3vgZwCx4XXU?si=DEii-X6CPYh8XLga" ;





const Descargas = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };


  return (
    <div className="container contOption">

    <div className="card mb-4">
    <div className="card-body">
      <h2 className="card-title">Descargas</h2>
      <div className="row">
          <div className="col-12 mt-3">

          <div className='row'>
                <div className='col-1 mt-2 d-flex justify-content-end'>
                    <span className="material-symbols-outlined">
                      info
                    </span>
                </div>
                <div className='col-10'>
                   <p className='text-secondary'> En este apartado encontraras todas las herramientas y recursos multimediales que utilizamos en el sitio para dar respuesta a tus solicitudes.</p>
               </div>

          </div>

          </div>
          <div className="col-12 mt-3">
          <div className="row">
                <div className="col-12 col-md-4 mb-4">
                  <div id="list-example" className="list-group">
                    <a className="list-group-item list-group-item-action" href="#list-item-1">Provincia - Desbloqueo / Arranques</a>
                    <a className="list-group-item list-group-item-action" href="#list-item-2">Nación - Desbloqueo</a>
                    <a className="list-group-item list-group-item-action" href="#list-item-3">Nación - Arranques</a>

                  </div>
                </div>
                <div className="col-12 col-md-8">
                  <div data-bs-spy="scroll" data-bs-target="#list-example" data-bs-smooth-scroll="true" className="scrollspy-example " tabindex="0">
                    <h4 id="list-item-1">Provincia - Desbloqueo / Arranques</h4>
                    <p className='card p-3 mt-3'>
                    <div className='row'>
                                        <div className='col-12'>
                                           <div className='row'>
                                             <div className='col-1'>
                                                <span className="material-symbols-outlined">counter_1</span>
                                             </div>
                                             <div className='col-10'><p>Una vez desbloqueada la computadora, debes seguir los siguientes pasos</p></div>
                                           </div>
                                        </div>

                          <div className='col-12 mt-3'>
                              <div className='row'>
                                <div className='col-1'>
                                  <span className="material-symbols-outlined">counter_2</span>
                                </div>
                                <div className='col-10'>
                                  <p>Descargá el archivo y seguí las intrucciones del video</p>
                                  </div>
                                
                              </div>
                              <div className='row'>
                                <div className='col-12 col-md-9  d-flex justify-content-center'>
                                    <a 
                                      className="btn btn-outline-success mt-3 mb-3" 
                                      href={IRM_2} 
                                      download="IRM_2.6.bat">
                                        Descarga
                                    </a>
                                </div>
                              </div>
                          </div>   

                          <div className='col-10 mt-2 mb-4 d-flex justify-content-center'>
                     
                                <iframe 
                                      width="360" 
                                      height="215" 
                                      src= {videoArrProvincia}
                                      title="Video explicativo de como cargar arranques en 2 minutos en la netbook de provinica"                                       
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                      >
                                  </iframe>
                            
                          </div>  
                       

                   

                      </div>



                    </p>

                    <h4 id="list-item-2">Nación - Desbloqueo</h4>
                    <p className='card p-3 mt-3'>
                            <div className='row'>
                          <div className='col-12'>
                          <div className='row'>
                              <div className='col-1'>
                                <span className="material-symbols-outlined">
                                  counter_1
                                </span>
                              </div>
                              <div className='col-10'>
                                  <p className="card-text">Una vez descargado el archivo tcopp y copiado al pendrive debe seguir las siguientes indicaciones</p>
                                  <p className='text-danger'> ATENCION: El archivo puede venir comprimido, debe descomprimir y obtener el archivo de nombre: tcopp</p>

                        
                              </div>
                        </div>
                          </div>
                          <div className='col-10 d-flex justify-content-center ' id='contPaqueteGenerico'>

                          </div>

                          <div className='col-10 mt-2 mx-4 mb-4'>
                          <div className="card-body">
                                      <div className='video-responsive'>
                                        <iframe 
                          
                                          src={urlAplicarPDP}
                                          title="Video explicativo de como descargar e instalar el agente" 
                                                                  
                                          >
                                        </iframe>
                                    </div> 
                              </div>


                              </div>

                        </div>



                    </p>


                    <h4 id="list-item-3">Nación - Arranques</h4>
                    <p className='card p-3 mt-3'>
                    <div className='col-12'>  
                    <div className='row'>
                              <div className='col-1'>
                                <span className="material-symbols-outlined">
                                  counter_1
                                </span>
                              </div>
                              <div className='col-10'>
                                  <p className="card-text">Luego de haber desbloqueado el equipos ya sea por codigo como por archivo tcopp, debe seguir los siguientes pasos</p>
                                      
                              </div>
                        </div>



                          < div className='row mt-4'>
                                <div className='col-1 mt-2 '>
                                  <span className="material-symbols-outlined">
                                    counter_2
                                  </span>
                                </div>
                                <div className='col-8'>
                                    <p className="card-text mt-2">Conectate a internet y abrí el AGENTE como a continuación</p>
                                    <img src={imgVerAgente} className="d-block w-100" alt="..."/>
                                </div>
                          </div>

                          <div className='row mt-4'>
                                <div className='col-1 mt-2 '>
                                  <span className="material-symbols-outlined">
                                    counter_3
                                  </span>
                                </div>
                                <div className='col-8'>
                                    <p className="card-text mt-2">En la pestaña configuración verifique que el servidor apunte a la URL</p>
                                    <img src={imgServidor} className="d-block w-100" alt="..."/>
                                    <p className='text-dark mt-2'>URL/Dirección del servidor: <mark>citd.dgp.educ.ar</mark></p>
                                </div>
                                <div className='col-12 mx-3 mt-4'>
                                <p className="lead">
                                   NOTA: Si no tenes el programa para cargar los arranques instalado, mirá el siguiente video:                               
                                </p>
                                </div>
                                <div className='col-12 d-flex justify-content-center'>
                                      <button
                                      className="btn btn-outline-primary mb-2"
                                      type="button"
                                      onClick={toggleCollapse}
                                      >
                                      Ver
                                      </button>
                                  </div>

                                <div className=' col-10 mt-2 mx-4 mb-4 '>
                                    <div className={`collapse${isOpen ? ' show' : ''}`}>
                                      <div className="card-body">
                                        <div className='video-responsive'>
                                          <iframe 
                                             width="560"
                                             height="315" 
                                             src={urlDescargaAgente}
                                             title="Video explicativo de como descargar e instalar el agente" 
                                                                              
                                             >
                                             </iframe>
                                          </div>   
                                          <a 
                                            className="btn btn-outline-success mt-3 mb-3" 
                                            href={Agente}
                                            download="ThefClient4.8.exe">
                                              Descargar
                                          </a>
                                          
                                       </div>
                                    </div>
                                </div>
                          </div>                   


                         
                            
                            </div>

                    </p>

                  </div>
                </div>
              </div>

          </div>

      </div>
    </div>
    </div>

    
    </div>
  );
};

export default Descargas;