import React, { useState } from 'react';


import Mensaje from './Mensaje';
import Provincia from './Provincia';
import JuanaManso from './JuanaManso';
import AprenderConectado from './AprenderConectado';
import Nacion from './Nacion';
import Descargas from './Descargas';



const options = [
  { id: 0, component: Mensaje, imageSrc: require('../assets/img/provincia2.jpg')  , title: 'Mensaje'},
  { id: 1, component: Provincia, imageSrc: require('../assets/img/provincia2.jpg')  , title: 'Provincia'},
  { id: 2, component: JuanaManso, imageSrc: require('../assets/img/juana2.jpg')  , title: 'Conectar Igualdad (2019-actualidad)'},
  { id: 3, component: AprenderConectado, imageSrc: require('../assets/img/aprender2.jpg')  , title: 'Aprender Conectados'},
  { id: 4, component: Nacion, imageSrc: require('../assets/img/nacion2.jpg') , title: 'Conectar Igualdad (hasta 2018)'}, 
  { id: 5, component: Descargas, imageSrc: require('../assets/img/Descargar.png') , title: 'Descargas'},
   
];




const Inicio = () =>{

  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = (pageIndex) => {

    console.log(pageIndex)
    setCurrentPage(pageIndex);
    scrollToContent();

  };

  const scrollToContent = () => {
    const contentElement = document.querySelector('.contOption');
    if (contentElement) {
      contentElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const CurrentComponent = options[currentPage].component;


const recargar = () => {
  //window.location.reload(true);
  handlePageChange(2); 


}



    return(


      <main className="container my-4">
      <div className="row">
        <div className="col-md-3">
        <div className="card contMenu">
              <div className="card-body">
               <div className='row'>
                
                  <div className='col-12 text-center' onClick={recargar}>
                    <h6 className="card-title"> Elija una opción:</h6>
                  </div>
                </div>
              </div>
            </div>

          <div className="menu mt-3">

            {options.map(option => (
              <div key={option.id} className={`menu-item ${currentPage === option.id  ? 'active' : ''} ${option.id == 0 && 'd-none'}`} onClick={() => handlePageChange(option.id)}>
                <img
                  src={option.imageSrc}
                  alt={`Opción ${option.id}`}
                  className="img-fluid"
                />
                <p className="menu-title-item text-center">{option.title} </p>
              </div>
             
            ))}
          </div>
          
        </div>

        

        <div className="col-md-9">
        <hr/>
          <div className="container">
            <CurrentComponent />
          </div>
        </div>
      </div>
    </main>


    );

};

export default Inicio;