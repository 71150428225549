
import React, { useState, useEffect} from 'react';
import Autocomplete from './Autocomplete';

import imgVerAgente from '../assets/img/veragente.png';
import imgServidor from '../assets/img/cambiardirservidor.jpg';

import Agente from '../assets/software/ThefClien4.8.exe';

const urlDescargaAgente = "https://www.youtube.com/embed/LQtBOiJ50qU?si=oGMUkmZ-6B8DiMrO" 
const urlAplicarPDP = "https://www.youtube.com/embed/ypw3f4RbtEA?si=zW-NyjywGLZgIxDT"




const Generico = () => {


    const respuestaInicial = {
        detalle: '',
        id: '',
        request: '',
        respuesta: '',
        solucion: ''
      }
    
 
      const [hwid, setHwid] = useState('');
      const [bt, setBt] = useState('');
      
      const [data, setData] = useState(respuestaInicial);
    
      const [isOpen, setIsOpen] = useState(false);

      const toggleCollapse = () => {
        setIsOpen(!isOpen);
      };
        
  
    let contAgenteGenerico = document.getElementById('contAgenteGenerico')

    const obtenerSolucionGenerico = (e) =>{

        //F6C5E7BFF4528C5ADC60
    
        let SNGenerico = document.getElementById('SNGenerico')
        let contError = document.getElementById('contError')
        contError.classList.add('d-none')

        if(SNGenerico.value == ''){
    
           console.log('va a busca*')
           SNGenerico.classList.add('is-invalid')
           SNGenerico.focus()
    
        }else if(SNGenerico.value == '81cd781af38f61c49a42' || SNGenerico.value == 'fe065ab52323227adeb8'){
              console.log('pe pe pe pe')
              contError.classList.remove('d-none')
        }else{
    
          e.preventDefault();
          SNGenerico.classList.remove('is-invalid')
          console.log('SN:', SNGenerico.value);
          console.log('HWID:', hwid);
          console.log('BT:', bt);
      
          let PARAMETROS = '?SN='+SNGenerico.value
          
          if(SNGenerico){
      
            setData(respuestaInicial)
            buscarSolucion(PARAMETROS);
          }
    
        }
    };

    const buscarSolucion = async (PARAMETROS) => {


            console.log(PARAMETROS) 
      
            let contNull = document.getElementById('contNull')
            contNull.classList.add('d-none')

            let contSolucionGenerico = document.querySelector('#contSolucionGenerico')
            
            let contPDPGenerico = document.querySelector('#contPDPGenerico')

            contAgenteGenerico && contAgenteGenerico.classList.add('d-none')
            contPDPGenerico.classList.add('d-none')
          
            contSolucionGenerico.classList.remove('d-none')
            contSolucionGenerico.scrollIntoView({ behavior: 'smooth' });
      
             await fetch('https://script.google.com/macros/s/AKfycbxBbYHBuHjqMPhGvwpY-RiqU3pTXOwbl-ibg6Lp3sRHiSJGS0uShno4vyF8iAkvy_nMAw/exec'+PARAMETROS)
             .then(response => response.json()) 
             .then(data => { 
            
                 console.log(data)
                 setData(data) 
      
                 if(data.request === false){
      
                    data.solucion = 'Paquete de migracion faltante, o la información proporcionada no es correcta.'
                    data.id = ': 0x0003'
                    data.respuesta = data.ultimaSincro

                    console.log("No encontramos solución pa")
                    contNull.classList.remove('d-none')
      
                    
                 }else{              
                    controlSolucion(data)
                 }
      
      
              })
             .catch(error => console.error('Error fetching data for Option 1:', error));  
        
             
             
        };
        

        const controlSolucion = (data) =>{

            let contPaqueteGenerico = document.getElementById('contPaqueteGenerico')
            let pdpSolucion = document.getElementById('pdpSolucion')
            let contPDPGenerico = document.querySelector('#contPDPGenerico')
          
            contAgenteGenerico && contAgenteGenerico.classList.add('d-none')
            contPDPGenerico.classList.add('d-none')
          
          
            if(data.solucion === 'MIGRACION' ){
          
              contPDPGenerico.classList.remove('d-none')
              contAgenteGenerico && contAgenteGenerico.classList.remove('d-none')
              pdpSolucion.classList.add('d-none')
          
              const btn_desbloqueo = downloadBase64File(data.archivo, data.nombre);
              console.log(btn_desbloqueo)
          
              contPaqueteGenerico.innerHTML = ''
              contPaqueteGenerico.appendChild(btn_desbloqueo)
             
          
            }

           };


        const cancelar = () =>{

          setData(respuestaInicial)
              //window.location.href = window.location.href;

           };

    function downloadBase64File(contentBase64, fileName) {
   
            const linkSource = `data:application/zip;base64 ,${contentBase64}`;
            const downloadLink = document.createElement('a');
          
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.id = "a_tcopp"
            downloadLink.setAttribute("class", "btn btn-outline-success");
            downloadLink.setAttribute("type", "button")
            downloadLink.innerText = "Descargar"
          
            return downloadLink; 
          };
        
    return(

       <div>

<div className="card mb-4">
    <div className="card-body">
        <div className='col-12 mt-3 ' id="contGenerico">
                
                <Autocomplete/> 

              <div className='col-12 d-none' id="contError">
                  <p className='text-warning'>Si usted posee ese numero en la computadora, no debe realizar este paso.</p>
              </div>
               <div className='col-12 d-flex justify-content-end'>  

                  <button 
                      type="submit" 
                      className="btn btn-outline-danger mt-3 mx-2" 
                      onClick={cancelar}>
                      Cancelar
                  </button>

                  <button 
                      type="submit" 
                      className="btn btn-outline-secondary mt-3 mx-2" 
                      onClick={obtenerSolucionGenerico}>
                      Continuar
                  </button>


                </div>
        </div>
</div>
</div>



  <div className={data.detalle == '' ? "card mb-4 mt-4 d-none" : 'card mb-4 mt-4'} id="contSolucionGenerico">

              <div className="card-body">
                <div className='row mt-2 mb-2'>
                        <div className='col-8'>
                          <h3 className='text-dark'>
                              Solución
                          </h3>
                        </div>
                        
                              {data.detalle != ''? (
                        
                                  <div className='col-12 mt-2 mb-3 mx-2'>
                                    <figcaption className="blockquote-footer">
                                        <cite title="Source Title">{data.solucion} {data.id ? '(ID '+data.id+')' : ''}</cite>
                                      </figcaption>
                                  </div>   

                              ):(
                                <div className='col-12 text-center'>

                                  <div className="spinner-border" role="status">
                                      <span className="visually-hidden">Loading...</span>
                                  </div>
                                  <br/>
                                  <p>Buscando</p>
                                </div>  
                              )
                              
                              }
                              <div className='col-12 d-none' id="contPDPGenerico">  
                              <div className='row'>
                                <div className='col-12'>
                                <div className='row'>
                                    <div className='col-1'>
                                      <span className="material-symbols-outlined">
                                        counter_1
                                      </span>
                                    </div>
                                    <div className='col-10'>
                                        <p className="card-text">Descargue el archivo y aplique segun intrucciones</p>
                                        <p className='text-danger'> ATENCION: El archivo puede venir comprimido, debe descomprimir y obtener el archivo de nombre: tcopp</p>

                                        <h4 className="card-title text-success text-center"> {data.respuesta} </h4>
                                    </div>
                              </div>
                                </div>
                                <div className='col-10 d-flex justify-content-center ' id='contPaqueteGenerico'>

                                </div>

                                <div className='col-10 mt-2 mx-4 mb-4'>
                                <div className="card-body">
                                            <div className='video-responsive'>
                                              <iframe 
                                
                                                src={urlAplicarPDP}
                                                title="Video explicativo de como descargar e instalar el agente" 
                                                                        
                                                >
                                              </iframe>
                                          </div> 
                                    </div>


                                    </div>

                              </div>
                            

                              </div> 

                              <div className='col-12' id='contAgenteGenerico'>  
                              < div className='row mt-4'>
                                    <div className='col-1 mt-2 '>
                                      <span className="material-symbols-outlined">
                                        counter_2
                                      </span>
                                    </div>
                                    <div className='col-8'>
                                        <p className="card-text mt-2">Conectate a internet y abrí el AGENTE como a continuación</p>
                                        <img src={imgVerAgente} className="d-block w-100" alt="..."/>
                                    </div>
                              </div>

                              <div className='row mt-4'>
                                    <div className='col-1 mt-2 '>
                                      <span className="material-symbols-outlined">
                                        counter_3
                                      </span>
                                    </div>
                                    <div className='col-8'>
                                        <p className="card-text mt-2">En la pestaña configuración verifique que el servidor apunte a la URL</p>
                                        <img src={imgServidor} className="d-block w-100" alt="..."/>
                                        <p className='text-dark mt-2'>URL/Direccion del servidor: <mark>citd.dgp.educ.ar</mark></p>
                                    </div>
                                    <div className='col-12 mx-3 mt-4'>
                                    <p className="lead">
                                      NOTA: Si no tenes el programa para cargar los arranques instalado, mirá el siguiente video:                               
                                    </p>
                                    </div>
                                    <div className='col-12 d-flex justify-content-center'>
                                          <button
                                          className="btn btn-outline-primary mb-2"
                                          type="button"
                                          onClick={toggleCollapse}
                                          >
                                          Ver
                                          </button>
                                      </div>

                                    <div className=' col-10 mt-2 mx-4 mb-4 '>
                                        <div className={`collapse${isOpen ? ' show' : ''}`}>
                                          <div className="card-body">
                                            <div className='video-responsive'>
                                              <iframe 
                                                width="560"
                                                height="315" 
                                                src={urlDescargaAgente}
                                                title="Video explicativo de como descargar e instalar el agente" 
                                                                                  
                                                >
                                                </iframe>
                                              </div>   
                                              <a 
                                                className="btn btn-outline-success mt-3 mb-3" 
                                                href={Agente}
                                                download="ThefClient4.8.exe">
                                                  Descargar
                                              </a>
                                              
                                          </div>
                                        </div>
                                    </div>
                              </div>                   


                            
                                
                                </div>
                </div>  
              </div>  
                <div className="card-body d-none " id="contNull">

                <div className='row mb-3' >
                      <div className='col-1 text-center'>
               
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                       
                      </div>
                      <div className='col-9'>

                          <p className="card-text">No obtuvimos respuesta del servidor</p>
                          <p className='text-danger'> ATENCION: Verifique la información proporcionada y vuelva intentar</p>

                          <h4 className="card-title text-success text-center"> {data.respuesta} </h4>
                      </div>
                </div>            
            </div>
</div>






       </div>





      )

};

export default Generico;