
import {Sponsor} from './Sponsor';
import Generico from './Generico';

function Nacion() {

  const urlVideoNacion = "https://www.youtube.com/embed/qF_yjzG52Mk?si=fxGtkZ9SpnYzpufi" ;

  
  return (

    <div className="container contOption">

    <div className="card mb-4">
    <div className="card-body">
      <h2 className="card-title">Equipos de Nación</h2>
      <div className="row">
          <div className="col-12 mt-3">

          <div className='row'>
                <div className='col-1 mt-2 d-flex justify-content-end'>
                    <span className="material-symbols-outlined">
                      error
                    </span>
                </div>
                <div className='col-8'>
                  <p className='text-warning'>
                      IMPORTANTE! Debés mirar el video propuesto atentamente, luego completar la información en los pasos que te proponemos a continuación.
                  </p>
                </div>
          </div>

          </div>
          <div className="col-12 d-flex justify-content-center contVideo">
              <iframe 
                width="560" 
                height="315" 
                className='video'
                src={urlVideoNacion}
                >
              </iframe>
          </div>

      </div>
    </div>
    </div>
     <div id='contSponsor' >
       <Sponsor huesped="G5"/>
     </div>
  {/*   
     <div id='contGenerico' className='d-none'>
       <Generico/>
    </div>
   */}  
    </div>
  );

}
export default Nacion;


