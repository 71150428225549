import React from "react";
import ReactDOM from "react-dom/client";

import './index.css';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css'; // Importa los estilos de Bootstrap


import logo_pipal from './assets/img/logo_pipal.png'
import logo_footer from './assets/img/logo_footer.png'


import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

const recargar = () => {
  window.location.reload(true);
}

const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
<div>



<nav className="navbar navbar-light " Style="background-color: #edf0f1;">
     <div className="container-fluid ">  
        <div className="col-12 d-flex justify-content-left align-items-center">
  
                    <img 
                        src= {logo_pipal} 
                        className="brand-logo mt-2"
                        onClick={recargar}
                    />
       
        </div> 
      </div>
    </nav>

      <MsalProvider instance={msalInstance}>
          <App />
      </MsalProvider>




  <nav>
    <div id="contFooter" className="row" >
       
        <div className="navbar navbar-light text-muted mt-auto " >
           <div className="col-12 d-flex  justify-content-center">
                <img 
                className="brand_banner " 
                src={ logo_footer } 
                alt="Ministerio banner"
      
                />
            </div> 
            <div className="col-12 col-md-12 d-flex justify-content-center mt-2">
                Contacto: desbloqueos@me.cba.gov.ar
            </div>
            <div className="col-12 col-md-12 text-center">
            © 2024 Copyright Ministerio de Educación de la Província de Córdoba- Subdirección de Infraestructura Tecnológica y Conectividad
            </div>        
        </div>
    </div>  
    </nav>

  </div>  
);

